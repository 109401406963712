export default class GtmClass {

    static createNewEvent(action,label,value) {
      window.dataLayer.push({
          event: 'event',
          eventProps: {
              action: action,
              label: label,
              value: value
          }
      });
    }
  }