import { Box, Typography } from "@mui/material";
import { secondaryColor } from "../../AppTheme";

export const Title = (props) => {
  return (
    <Box sx={{ textAlign: "center" }}>
      <Typography
        sx={{
          fontSize: "40px",
          textAlign: "center",
          paddingLeft: "20px",
          paddingRight: "20px",
          margin: "10px",
          display: "inline-block",
          borderBottom: `1.5px solid ${secondaryColor}`,
        }}
        variant="h1"
      >
        {props.text}
      </Typography>
    </Box>
  );
};
