import {
  ListItemText,
  Box,
  Container,
  MenuItem,
  MenuList,
  Paper,
  Typography,
  Grid,
} from "@mui/material";

import { APP_CONSTS } from "../../APP/APP_CONSTS.js";
import { AppDict } from "../../includes/AppDictionary.js";
export const AppFooter = () => {
  return (
    <Paper
      sx={{
        bottom: 0,
        width: "100%",
      }}
      component="footer"
      square
      variant="outlined"
    >
      <Container maxWidth="lg">
        <Box
          sx={{
            flexGrow: 1,
            justifyContent: "center",
            display: "flex",
            my: 1,
          }}
        >
          <div>
            {/* <Image priority src="/Logo.svg" width={75} height={30} alt="Logo" /> */}
          </div>
        </Box>
        <Box
          sx={{
            flexGrow: 1,
            justifyContent: "center",
            display: "flex",
          margin:"auto",
            mb: 2,
          }}
        >
          <FooterMenu />
        </Box>

        <Box
          sx={{
            flexGrow: 1,
            justifyContent: "center",
            display: "flex",
            mb: 2,
          }}
        >
          <Typography variant="caption" color="initial">
            {APP_CONSTS.APP_NAME} ©{new Date().getFullYear()}.
          </Typography>
        </Box>
      </Container>
    </Paper>
  );
};

const getFooterMenuColumns = () => {
  return [
    {
      title: "קישורים חשובים",
      links: [
        { anchor: "דף הבית", url: "https://www.saveli.co.il" },
        { anchor: "השוואת נסיעות", url: "/" },
        {
          anchor: "יעדים מומלצים",
          url: "https://www.saveli.co.il/attractions/",
        },
      ],
    },
    {
      title: "מידע כללי",
      links: [
        { anchor: "תקנון שימוש", url: "https://www.saveli.co.il/policy" },
        { anchor: "חברות ביטוח ומחירונים", url: "https://app.saveli.co.il/pages/companies" },
        { anchor: "צור קשר", url: "https://www.saveli.co.il/contact" },
        { anchor: "שאלות נפוצות", url: "https://www.saveli.co.il/faq/" },
      ],
    },
  ];
};

const FooterMenu = () => {
  const footerMenuColumns = getFooterMenuColumns();
  return (
    <Grid container spacing={4}>
      {footerMenuColumns.map((col, index) => {
        return (
          <Grid item xs={4} key={index} sx={{ textAlign: "center" }}>
             <h3>{col.title}</h3>
            <MenuList>
              {col["links"].map((link, index2) => {
                return (
                  <MenuItem key={index2}>
                    <ListItemText sx={{ fontSize: "14px" }}>
                      <a className="footer-menu-item-link" href={link["url"]}>
                        {link["anchor"]}
                      </a>
                    </ListItemText>
                  </MenuItem>
                );
              })}
            </MenuList>
          </Grid>
        );
      })}
    </Grid>
  );
};
