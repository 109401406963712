import {
  Box,
  FormControl,
  Grid,
  Container,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Alert,
} from "@mui/material";
import { useEffect, useState } from "react";
import { AppDict } from "../../includes/AppDictionary";
import { PassangerDetailCard } from "./PassangerDetailsCard";
import { NextButtonForCompareStep } from "./NextButtonForCompareStep";
import { getDefaultPassangerList } from "../../utils";

export const PassangersDetailsStep = (props) => {
  const [passangerList, setPassangersList] = useState(
    getDefaultPassangerList()
  );
  const [numberOfPassangers, setNumberOfPassangers] = useState(1);
  const [detailsComplete, setDetailsComplete] = useState();

  const [showInputErrors, setShowInputErrors] = useState(false);
  const isValidStep = useState(false);

  // TODO: check if step valid

  const handleNumberOfPassangersChange = (event) => {
    const num = event.target.value;

    let newPassangerObj = Array(num);
    const passangerListLength = passangerList.length;
    for (let i = 0; i < num; i++) {
      if (i < passangerListLength) {
        newPassangerObj[i] = passangerList[i];
      } else {
        newPassangerObj[i] = { passangerIndex: i };
        setDetailsComplete(false);
      }
    }
    setPassangersList(newPassangerObj);
  };

  const onClickNextHandler = () => {
    // TODO: validate data/
    if (!isPassangerDataValid(passangerList)) {
      setShowInputErrors(true);
      return;
    }
    // if vaild update
    props.sendStepDataToMainComponent(passangerList);
    props.goToNextStep(passangerList);
  };

  const isPassangerDataValid = (passangerList) => {
    for (let i = 0; i < passangerList.length; i++) {
      if (
        !passangerList[i]["age"] ||
        !passangerList[i]["firstName"] ||
        !passangerList[i]["lastName"]
      ) {
        return false;
      }
    }
    return true;
  };

  const updateSpecificPassangerDetail = (index, data) => {
    let newPassangerList = [...passangerList];
    newPassangerList[index] = data;
    setPassangersList(newPassangerList);
    props.sendStepDataToMainComponent(newPassangerList);
  };

  useEffect(() => {
    setPassangersList(props["passangerList"]);
  }, [props]);

  return (
    <Container maxWidth="xl">
      <Box sx={{ width: "300px", margin: "auto" }}>
        <Box
          sx={{
            margin: "auto",
            flexWrap: "wrap",
            display: "flex",
            margin: 0,
            padding: 0,
            justifyContent: "center",
          }}
        >
          <Box sx={{ mx: "auto", flex: 1 }}>
            <FormControl fullWidth>
              <InputLabel id="demo-simple-select-label">
                {AppDict.number_of_passangers}
              </InputLabel>
              <Select
                value={passangerList ? passangerList.length : 1}
                label="Age"
                onChange={handleNumberOfPassangersChange}
              >
                {[...Array(10)]
                  .map((_, i) => i + 1)
                  .map((t) => (
                    <MenuItem value={t} key={t}>
                      {t}
                    </MenuItem>
                  ))}
              </Select>
            </FormControl>
          </Box>
          <Box sx={{ mx: "auto", flex: 1 }}>
            <TextField
              fullWidth
              id="outlined-basic"
              label={AppDict.main_email}
              variant="outlined"
            />
          </Box>
        </Box>
      </Box>
      <Box>
        {showInputErrors ? (
          <Alert severity="warning">{AppDict.passangerDataError}</Alert>
        ) : (
          ""
        )}
      </Box>
      <Grid container spacing={2}>
        {passangerList &&
          passangerList.map((t, index) => (
            <Grid key={`passanterDetailCard${index}`} item md={3} xs={12}>
              <PassangerDetailCard
                key={index}
                passangerNumber={index + 1}
                passangerObj={passangerList[index]}
                updateSpecificPassangerDetail={(data) => {
                  updateSpecificPassangerDetail(index, data);
                }}
              ></PassangerDetailCard>
            </Grid>
          ))}
      </Grid>
      <Box sx={{ textAlign: "center", my: "10px" }}>
        <NextButtonForCompareStep
          onClickNextHandler={onClickNextHandler}
          text={AppDict.continue}
        ></NextButtonForCompareStep>
      </Box>
    </Container>
  );
};
