import { red } from "@mui/material/colors";
import { createTheme } from "@mui/material/styles";
import { heIL as coreheIL } from "@mui/material/locale";
import { heIL } from "@mui/x-date-pickers/locales";
import { paperClasses } from "@mui/material";

export const primaryColor = "#629D96";
export const secondaryColor = "#E0906B";
export const primaryLightColor = "#629d969c";
export const consentsColor = "#575b60";
const primaryDarkColor = "#396a64";
const primaryTextColor = "#333333";
const disabledTextColor = "#6e6e6e";
// const secondaryColor = "#000";
const secondaryTextColor = "#000";
const paperColor = "#EEEFEF";

const typographyStyles = {
  fontFamily: "Patrick+Hand",
  fontSize: 18,
  lineHeight: 2,
  letterSpacing: 0.32,
  useNextVariants: true,
  suppressDeprecationWarnings: true,
  h6: {
    fontWeight: 600,
  },
};

export const secondaryTheme = createTheme({
  direction: "rtl",
  heIL, // x-date-pickers translations
  coreheIL, // core translations
  typography: typographyStyles,
  palette: {
    text: {
      default: primaryTextColor,
      primary: primaryTextColor,
      secondary: secondaryTextColor,
      paper: "#000",
      light: primaryTextColor,
      disabled: disabledTextColor,
      backgroundContrastText: primaryTextColor,
      secondaryContrastColor: "#000",
    },
    background: {
      default: "#2c3f600d",
      dark: primaryTextColor,
      paper: "#fff",
      card2: "#",
      card3: "#",
      light: "#",
      paper: "#000",
    },
    secondary: {
      main: primaryTextColor,
      light: "#d8bb6d",
      dark: "#2c3f600d",
    },
    success: {
      main: "#48dead",
    },
    primary: {
      main: primaryColor,
      light: primaryLightColor,
      dark: primaryDarkColor,
    },
    paper: {
      main: paperColor,
    },
    error: {
      main: primaryTextColor,
    },
  },
  field: {
    borderColor: primaryTextColor,
  },
  components: {
    MuiTextField: {
      defaultProps: { background: "#fff" },
      styleOverrides: {
        // Name of the slot
        root: {
          // Some CSS
          backgroundColor: "#fff",
        },
      },
    },

    MuiOutlinedInput: {
      styleOverrides: {
        backgroundColor: "red",

        borderColor: primaryTextColor,
        direction: "rtl",
      },
    },

    MuiInputBase: {
      styleOverrides: {
        backgroundColor: "red",
        input: {
          backgroundColor: "red",
          color: primaryTextColor,
          outlineColor: primaryTextColor,
          borderColor: primaryTextColor,
          direction: "ltr",
        },
      },
    },
    MuiSwitch: {
      styleOverrides: {
        switchBase: {
          // width:"100px",
          // color:"red"
        },
      },
    },
  },
});

export const defaultTheme = createTheme({
  direction: "rtl",
  heIL, // x-date-pickers translations
  coreheIL, // core translations
  typography: typographyStyles,
  palette: {
    text: {
      default: primaryTextColor,
      primary: primaryTextColor,
      secondary: secondaryTextColor,
      light: primaryTextColor,
      disabled: disabledTextColor,
      backgroundContrastText: primaryTextColor,
      secondaryContrastColor: "#000",
    },
    background: {
      default: "#2c3f600d",
      dark: primaryTextColor,
      paper: "#fff",
      card2: "#",
      card3: "#",
      light: "#",
    },
    secondary: {
      main: primaryTextColor,
      light: "#d8bb6d",
      dark: "#2c3f600d",
    },
    success: {
      main: "#48dead",
    },
    primary: {
      main: primaryColor,
      light: primaryLightColor,
      dark: primaryDarkColor,
    },
    paper: {
      main: paperColor,
      light: paperColor,
      dark: paperColor,
    },
    error: {
      main: primaryTextColor,
    },
  },
  field: {
    borderColor: primaryTextColor,
  },
  components: {
    MuiTextField: {
      styleOverrides: {
        // Name of the slot
        root: {
          // Some CSS
          backgroundColor: "#fff",
        },
      },
    },
    MuiSelect: {
      styleOverrides: {
        // Name of the slot
        root: {
          // Some CSS
          backgroundColor: "#fff",
        },
      },
    },
    MuiOutlinedInput: {
      styleOverrides: {
        borderColor: primaryTextColor,
        direction: "rtl",
      },
    },
    MuiInputBase: {
      styleOverrides: {
        input: {
          color: primaryTextColor,
          outlineColor: primaryTextColor,
          borderColor: primaryTextColor,
          direction: "ltr",
        },
      },
    },
    MuiSwitch: {
      styleOverrides: {
        switchBase: {
          // width:"100px",
          // color:"red"
        },
      },
    },
  },
});
