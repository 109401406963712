import * as React from "react";
import { useState, useEffect } from "react";
import Stack from "@mui/material/Stack";
import { Box, Grid, Typography } from "@mui/material";
import { DestinationAndDatesStep } from "./DestinationAndDatesStep";
import { AppDict } from "../../includes/AppDictionary";
import { PassangersDetailsStep } from "./PassangersDetailsStep";
import { InsuranceOptionsStep } from "./InsuranceOptionsStep";
import { useSearchParams } from "react-router-dom";
import {
  getBasicPolicyPrices,
  getCompaniesList,
  getCompanyResultsMetaData,
  getCountryListData,
  getInsuranceOptionsPricesList,
} from "../../controller/dataController";
import { ResultsPanelByCompany } from "./ResultsPanelByCompany";
import { StepperProgressBar } from "./StepperProgressBar";
import { MainCompareClass } from "../../model/MainCompareClass";
import GtmClass from "../../model/GtmClass";

/* TODO: current bug - this compoenent not getting all the data as it should
when each step load it update it's data
instead of update all the data anyway in one function
*/
const DESTINATION_AND_DATE_STEP_NUMBER = 0;
const PASSANGERS_DETAILS_STEP_NUMBER = 1;
const INSURANCE_OPTIONS_STEP_NUMBER = 2;
const INSURANCE_COMPANY_RESULTS_STEP_NUMBER = 3;

export default function MainCompareStepper() {
  /** STATES */
  const [activeStepsCounter, setActiveStepsCounter] = useState(
    DESTINATION_AND_DATE_STEP_NUMBER
  );
  const [countryList, setCountryList] = useState([]);
  const [companiesList, setCompaniesList] = useState([]);
  const [basicPolicyPrices, setBasicPolicyPrices] = useState([]);
  const [insuranceOptionsPricesList, setInsuranceOptionsPricesList] = useState(
    {}
  );
  const [durationInDays, setDurationInDays] = useState(1);
  const [refreshResultPanel, setRefreshResultPanel] = useState(1);
  const [compareObjDataChangedCounter, setCompareObjDataChangedCounter] =
    useState(0);
  const [mainCompareObj, setMainCompareObj] = useState(
    new MainCompareClass((newIntval) => {
      setCompareObjDataChangedCounter(newIntval);
    })
  );

  const [companiesResultsMetaData, setCompanyResultsMetaData] = useState({});

  const [searchParams, setSearchParams] = useSearchParams();

  const onClickNextStepButtonHandler = async (stepIndex, dataObj) => {
    if (!dataObj) return;
    // TODO:
    mainCompareObj.updateStepData(stepIndex, dataObj);
    changeActiveStep(stepIndex + 1);
  };
  const changeActiveStep = (newStepNumber) => {
    // TODO: before we are checking if the step is active, we have to pull the updated data from the current step

    let isPreviousStepValid =
      newStepNumber == 0 ? true : mainCompareObj.isStepValid(newStepNumber - 1); // 0 always can move to step 0.
    if (
      mainCompareObj.validateStepNumberValue(newStepNumber) &&
      isPreviousStepValid
    ) {
      // TODO:validate previous steps are done.
      let newSearchParams = { activeStepNumber: newStepNumber };
      if (searchParams.entries()) {
        // copying all the search params
        let params = searchParams.entries();
        for (const [key, value] of params) {
          if (key != "activeStepNumber") {
            newSearchParams[key] = value;
          }
        }
      }
      mainCompareObj.saveToLocalStorage();
      setRefreshResultPanel(refreshResultPanel + 1);
      setSearchParams(newSearchParams);
      initialDataForStep(newStepNumber);
      setActiveStepsCounter(newStepNumber);
      GtmClass.createNewEvent("next step btn", "step number", newStepNumber);
    } else {
    }
  };

  // this function only update the main stepper object.
  const updateStepDataInMainObject = (stepIndex, stepData) => {
    mainCompareObj.updateStepData(stepIndex, stepData);
  };

  const initialCompanyResultMetaData = async () => {
    if (Object.keys(companiesResultsMetaData).length !== 0) return; // if alredy has data dont make request
    let res = await getCompanyResultsMetaData();

    // ordering the object
    let tempCompResMeta = {};
    if (res) {
      res.forEach((element) => {
        tempCompResMeta[element["id"]] = element;
      });
    }
    setCompanyResultsMetaData(tempCompResMeta);
  };

  // // UDPATE STEP DATA FROM LOCAL STORAGE.
  // const updateStepDataInLocalStorage = (stepIndex, stepData) => {
  //   mainCompareObj.updateStepData(stepIndex, stepData);
  //   // save all the data to localstorage
  //   localStorage.setItem("policy", JSON.stringify(mainCompareObj));
  //   localStorage.setItem(
  //     getAppSignatureKey("policy"),
  //     getSignatureForString(JSON.stringify(mainCompareObj))
  //   );
  // };

  const updateCompaniesList = async () => {
    let companiesListTemp = await getCompaniesList();
    setCompaniesList(companiesListTemp);
  };

  const updateBasicPricePolicy = async () => {
    let basicPolicyPrices = await getBasicPolicyPrices(
      mainCompareObj.toJsonObj()
    );
    setBasicPolicyPrices(basicPolicyPrices);
  };

  const updateInsuranceOptionsPricesList = async () => {
    let insuranceOptionsPricesListTemp = await getInsuranceOptionsPricesList(
      mainCompareObj.toJsonObj()
    );
    setInsuranceOptionsPricesList(insuranceOptionsPricesListTemp);
  };

  const optionsDataChanged = (updatedPassangerList) => {
    mainCompareObj.updatePassangerList(updatedPassangerList);
  };

  const initialDataForStep = (step) => {
    if (step == 2 || step == 3) {
      updateInsuranceOptionsPricesList();
      updateCompaniesList();
    }
    if (step == 2 || step == 3) {
      updateBasicPricePolicy();
    }

    let tempDurationInDays =
      mainCompareObj.destinationAndDate.getDurationInDays();
    setDurationInDays(tempDurationInDays);
  };

  // on reload page
  useEffect(() => {
    async function fetchData() {
      // You can await here
      let res = await getCountryListData();
      setCountryList(res);

      initialCompanyResultMetaData();
    }
    fetchData();
    // alert();
    mainCompareObj.getAllDataFromLocalStorage(); // changes comparision data
  }, []);

  useEffect(() => {
    // TODO: try to fix , not working well when reloading, synchronization issue.
    // when reloading we need first to update the comparisionData
    let activeStepNumber = searchParams.get("activeStepNumber");
    let newActiveStepNumber = 0;
    if (mainCompareObj.validateStepNumberValue(activeStepNumber)) {
      newActiveStepNumber = activeStepNumber;
    }
    setActiveStepsCounter(parseInt(newActiveStepNumber));

    initialDataForStep(newActiveStepNumber);
  }, [compareObjDataChangedCounter]);

  const getFromDateText = () => {
    return "";
    // let date = new dayjs(comparisionData["destinationAndDate"]["fromDate"]);
    // return date.format("DD-MM-YYYY");
  };

  const getToDateText = () => {
    return "";

    // let date = new dayjs(comparisionData["destinationAndDate"]["toDate"]);
    // return date.format("DD-MM-YYYY");
  };
  

  const ResultPanel = (props) => {
    const position =
      props["displayType"] == "vertical"
        ? { xs: "relative", sm: "relative", md: "relative", lg: "relative" }
        : {
            xs: "fixed",
            sm: "fixed",
            md: "relative",
            lg: "relative",
          };
    return (
      <Box
        sx={{
          zIndex: "100",
          // marginTop: "calc(10% + 50px)",
          width: "100%",
          position: { ...position },
          bottom: 0,
          width: "100%",
        }}
      >
        <Box
          sx={{
            textAlign: "center",
            display: {
              xs: "none",
              sm: "none",
              md: "block",
              lg: "block",
            },
          }}
        >
          <Typography>
            {AppDict.vacationTo}
            {mainCompareObj.destinationAndDate.getDestinationName()}
          </Typography>
          <Typography>
            {AppDict.total} {durationInDays} {AppDict.days}
          </Typography>
        </Box>
        <ResultsPanelByCompany
          displayType={props["displayType"]}
          companiesList={companiesList}
          comparisionData={mainCompareObj.toJsonObj()}
          basicPolicyPrices={basicPolicyPrices}
          insuranceOptionsPricesList={insuranceOptionsPricesList}
          durationInDays={durationInDays}
          refreshResultPanel={refreshResultPanel}
          companiesResultsMetaData={companiesResultsMetaData}
          stepNumber={activeStepsCounter}
        ></ResultsPanelByCompany>
      </Box>
    );
  };

  return (
    <Box sx={{ display: "flex", flexDirection: "column" }}>
      <Stack direction="column" sx={{ width: "100%" }} spacing={4}>
        <StepperProgressBar
          selectedCountryCode={mainCompareObj.destinationAndDate.getCountryCode()}
          activeStep={activeStepsCounter}
          changeActiveStep={changeActiveStep}
          numberOfPassangers={mainCompareObj.passangerList.getNumberOfPassangers()}
        ></StepperProgressBar>
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            borderRadius: 1,
          }}
        >
          {activeStepsCounter == DESTINATION_AND_DATE_STEP_NUMBER ? (
            <DestinationAndDatesStep
              goToNextStep={(data) => {
                onClickNextStepButtonHandler(
                  DESTINATION_AND_DATE_STEP_NUMBER,
                  data
                );
              }}
              stepData={mainCompareObj.destinationAndDate.getData()}
              countryList={countryList}
              sendStepDataToMainComponent={(stepData) => {
                updateStepDataInMainObject(
                  DESTINATION_AND_DATE_STEP_NUMBER,
                  stepData
                );
              }}
            />
          ) : (
            ""
          )}
          {activeStepsCounter == PASSANGERS_DETAILS_STEP_NUMBER ? (
            <PassangersDetailsStep
              passangerList={mainCompareObj.passangerList.getData()}
              goToNextStep={(data) => {
                onClickNextStepButtonHandler(
                  PASSANGERS_DETAILS_STEP_NUMBER,
                  data
                );
              }}
              sendStepDataToMainComponent={(stepData) => {
                updateStepDataInMainObject(
                  PASSANGERS_DETAILS_STEP_NUMBER,
                  stepData
                );
              }}
            />
          ) : (
            ""
          )}
          {activeStepsCounter == INSURANCE_OPTIONS_STEP_NUMBER ? (
            <Grid
              container
              sx={{
                margin: "auto",
              }}
            >
              <Grid key={2} item xs={12} sm={3}>
                <ResultPanel displayType="regular"></ResultPanel>
              </Grid>
              <Grid sx={{ marginBottom: "300px" }} key={1} item xs={12} sm={6}>
                <InsuranceOptionsStep
                  optionsDataChanged={(updatedPassangerList) => {
                    mainCompareObj.updatePassangerList(updatedPassangerList);
                  }}
                  insuranceOptionsPricesList={insuranceOptionsPricesList}
                  passangerList={mainCompareObj.passangerList.getData()}
                  goToNextStep={(data) => {
                    onClickNextStepButtonHandler(
                      INSURANCE_OPTIONS_STEP_NUMBER,
                      data
                    );
                  }}
                  sendStepDataToMainComponent={(stepData) => {
                    updateStepDataInMainObject(
                      INSURANCE_OPTIONS_STEP_NUMBER,
                      stepData
                    );
                  }}
                />
              </Grid>
            </Grid>
          ) : (
            ""
          )}
          {activeStepsCounter == INSURANCE_COMPANY_RESULTS_STEP_NUMBER ? (
            <div>
              <ResultPanel displayType="vertical"></ResultPanel>
            </div>
          ) : (
            ""
          )}
        </Box>
      </Stack>
    </Box>
  );
}
