import { CompareStepClass } from "./CompareStepClass";

export class PassangerStepClass extends CompareStepClass {
  constructor(stepData) {
    super(stepData);
  }

  updateStepData(newData) {
    this.data = newData;
  }

  getNumberOfPassangers() {
    let passangerList = super.getData();
    return passangerList && passangerList.length ? passangerList.length : "";
  }

  isStepValid(testData = null) {
    let passangerList;
    if (testData) {
      passangerList = testData;
    } else {
      passangerList = super.getData();
    }

    if (!Array.isArray(passangerList)) return false;
    if (passangerList.length <= 0) return false;

    for (const passanger of passangerList) {
      if (
        passanger["age"] > 0 &&
        passanger["firstName"] &&
        passanger["lastName"]
      ) {
        continue;
      } else {
        return false;
      }
    }
    return true;
  }
}
