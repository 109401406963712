export const APP_CONSTS = {
  // Host :
  APP_SERVER_HOST: process.env.REACT_APP_SERVER_HOST,

  // Data - Strings :
  APP_NAME: "Save Li",

  APP_INFINITY_DAYS_REPRESENT: -1,
  POLICY_VERSION: 1.02,
};
