import {
  Box,
  Step,
  StepLabel,
  Stepper,
  Typography,
  styled,
} from "@mui/material";
import { AppDict } from "../../includes/AppDictionary";
import FlightIcon from "@mui/icons-material/Flight";
import AddBoxIcon from "@mui/icons-material/AddBox";
import HealthAndSafetyIcon from "@mui/icons-material/HealthAndSafety";
import GroupAddIcon from "@mui/icons-material/GroupAdd";
import DoneIcon from "@mui/icons-material/Done";
import StepConnector, {
  stepConnectorClasses,
} from "@mui/material/StepConnector";
import { primaryColor, primaryLightColor } from "../../AppTheme";
const steps = [
  { stepLabel: AppDict.destination },
  { stepLabel: AppDict.passaners },
  { stepLabel: AppDict.extras },
  { stepLabel: AppDict.insurance },
];

const ColorlibConnector = styled(StepConnector)(({ theme }) => ({
  [`&.${stepConnectorClasses.alternativeLabel}`]: {
    top: "13px",
  },
  [`&.${stepConnectorClasses.active}`]: {
    [`& .${stepConnectorClasses.line}`]: {
      backgroundImage:
        "linear-gradient(8deg, rgba(98,157,150,1) 20%, rgba(238,239,239,1) 100%);",
      backgorund: "rgb(98,157,150)",
    },
  },
  [`&.${stepConnectorClasses.completed}`]: {
    [`& .${stepConnectorClasses.line}`]: {
      backgroundColor: primaryColor,
    },
  },
  [`& .${stepConnectorClasses.line}`]: {
    height: 3,
    border: 0,
    backgroundColor:
      theme.palette.mode === "dark" ? theme.palette.grey[800] : "#eaeaf0",
    borderRadius: 1,
  },
}));

const ColorlibStepIconRoot = styled("div")(({ theme, ownerState }) => ({
  backgroundColor:
    theme.palette.mode === "dark" ? theme.palette.grey[700] : "#ccc",
  zIndex: 1,
  color: "#fff",
  width: "30px",
  height: "30px",
  display: "flex",
  borderRadius: "50%",
  justifyContent: "center",
  alignItems: "center",
  backgroundColor: primaryLightColor,

  ...(ownerState.active && {
    backgroundColor: primaryColor,
  }),
  ...(ownerState.completed && {
    backgroundColor: primaryColor,
  }),
}));

const iconStyle_sx = { width: "20px" };
const currentIconStyle_sx = { width: "40px" };

export const StepperProgressBar = (props) => {
  function ColorlibStepIcon(props) {
    const { active, completed, className } = props;

    const icons = {
      // 1: <FlightIcon sx={{ width: "40px" }} />,
      // 2: <GroupAddIcon sx={iconStyle_sx} />,
      // 3: <AddBoxIcon sx={iconStyle_sx} />,
      // 4: <HealthAndSafetyIcon sx={iconStyle_sx} />,
      1: <Typography>1</Typography>,
      2: <Typography>2</Typography>,
      3: <Typography>3</Typography>,
      4: <Typography>4</Typography>,
    };

    return (
      <ColorlibStepIconRoot
        ownerState={{ completed, active }}
        className={className}
      >
        {completed ? <DoneIcon /> : icons[String(props.icon)]}
      </ColorlibStepIconRoot>
    );
  }
  return (
    <Stepper
      alternativeLabel
      activeStep={props.activeStep}
      connector={<ColorlibConnector />}
    >
      {steps.map((step, index) => (
        <Step
          sx={{ cursor: "pointer" }}
          onClick={() => {
            props.changeActiveStep(index);
          }}
          key={step["stepLabel"]}
        >
          <StepLabel StepIconComponent={ColorlibStepIcon}>
            {step["stepLabel"]}
            {index == 0 ? (
              <Box
                sx={{
                  margin: "5px",
                  fontSize: "22px",
                  borderRadius: "100%",
                }}
                className={`fi fi-${String(
                  props["selectedCountryCode"]
                ).toLowerCase()} fis`}
              ></Box>
            ) : (
              ""
            )}
            {index == 1 ? <span>({props["numberOfPassangers"]})</span> : ""}
            {}
          </StepLabel>
        </Step>
      ))}
    </Stepper>
  );
};
