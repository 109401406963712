export class CompareStepClass {
  constructor(stepData) {
    this.data = stepData;
  }

  isStepValid() {
    return false;
  }

  toJSON() {
    return { data: this.stepData };
  }

  toString() {
    return this.data;
  }

  updateStepData() {}

  getData() {
    return this.data;
  }
}
