import dayjs from "dayjs";
import {
  calculateDifferenceInDays,
  calculateDurationInDays,
  getDefaultDestinationStepData,
} from "../utils";
import { CompareStepClass } from "./CompareStepClass";

export class DestinationAndDatesStepClass extends CompareStepClass {
  constructor(stepData) {
    super(stepData);
    if (this.isStepValid(stepData)) {
      // do nothing.....data is ok
    } else {
      let data = getDefaultDestinationStepData();
      super.updateStepData(data);
    }
  }

  isStepValid(testData = null) {
    let data;
    // if has input it checking it, else it checking the current data
    if (testData) {
      data = testData;
    } else {
      data = super.getData();
    }
    let fromDate = data["fromDate"];
    let toDate = data["toDate"];
    let today = new dayjs();
    let selectedDestination = data["selectedDestination"];
    let duration = calculateDurationInDays(fromDate, toDate); // this value has to be positive , 1 or bigger
    let differenceFromToday = calculateDifferenceInDays(today, fromDate); // this value has to be positive or 0
    if (duration >= 0 && differenceFromToday >= 0 && selectedDestination) {
      return true;
    } else {
      return false;
    }
  }

  updateStepData(newData) {
    if (this.isStepValid(newData)) {
      this.data = newData;
      return true;
    }
    return false;
  }

  getDurationInDays() {
    let data = super.getData();
    let fromDate = data["from_date"];
    let toDate = data["to_date"];
    return calculateDurationInDays(fromDate, toDate);
  }

  getDestinationName() {
    let data = super.getData();
    return data["selectedDestination"]
      ? data["selectedDestination"]["country_display_name_he"]
      : "";
  }

  getCountryCode() {
    let data = super.getData();
    return data["selectedDestination"]
      ? data["selectedDestination"]["country_code"]
      : "";
  }
}
