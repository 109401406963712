import {
  Box,
  ImageList,
  ImageListItem,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import { useEffect, useState } from "react";
import { getCompaniesList } from "../../controller/dataController";
import { AppDict } from "../../includes/AppDictionary";
import Paper from "@mui/material/Paper";

export const TaarifonTable = (props) => {
  const [companiesData, setCompaniesData] = useState([]);

  const initialList = async () => {
    const list = await getCompaniesList();
    setCompaniesData(list);
    console.log(list);
  };

  useEffect(() => {
    initialList();
  }, []);

  return (
    <Box>
      <TableContainer component={Paper} sx={{ textAlign: "center" }}>
        <Table sx={{ minWidth: 650 }} aria-label="simple table">
          <TableHead>
            <TableRow>
              <TableCell align="center"></TableCell>
              <TableCell align="center">{AppDict.company}</TableCell>
              <TableCell align="center">{AppDict.taarifon}</TableCell>
              <TableCell align="center">{AppDict.company_link}</TableCell>
            </TableRow>
          </TableHead>
          <TableBody sx={{ textAlign: "center" }}>
            {companiesData.length > 0 &&
              companiesData.map((comp, index) => {
                return (
                  <TableRow
                    key={index}
                    sx={{
                      textAlign: "center",
                      "&:last-child td, &:last-child th": { border: 0 },
                    }}
                  >
                    <TableCell align="center">
                      <ImageList
                        sx={{
                          margin: "auto",
                          width: "100%",
                          maxWidth: "100px",
                          maxHeight: "60px",
                        }}
                      >
                        <ImageListItem>
                          <img
                            src={`/company_icons/${comp["id"]}-logo.png`}
                            loading="lazy"
                          />
                        </ImageListItem>
                      </ImageList>
                    </TableCell>
                    <TableCell align="center">
                      {comp["company_display_name_he"]}
                    </TableCell>
                    <TableCell align="center">
                      {comp["current_taarifon_date"]}
                    </TableCell>
                    <TableCell align="center">
                      <a href={comp["link"]} target="_blank">
                        {AppDict.click_here}
                      </a>{" "}
                    </TableCell>
                  </TableRow>
                );
              })}
          </TableBody>
        </Table>
      </TableContainer>
    </Box>
  );
};
