// import { getUserToken } from "./APP_AUTH";

import { APP_CONSTS } from "./APP_CONSTS";
/**
 * This module mapping all the http request of the UI.
 */

const AUTHENTICATION_ENPOINT = `${APP_CONSTS.APP_SERVER_HOST}authentication`;
const MANAGE_COMPANY_ENDPOINT = `${APP_CONSTS.APP_SERVER_HOST}manage-company`;

export const APP_ROUTES = {
  Companies: {
    companyList: `${MANAGE_COMPANY_ENDPOINT}/company-list`,
    companyDetails: `${MANAGE_COMPANY_ENDPOINT}/company-details`,
    addBasicPolicyRow: `${MANAGE_COMPANY_ENDPOINT}/add-basic-policy-row`,
    updateBasicPolicyRow: `${MANAGE_COMPANY_ENDPOINT}/update-basic-policy-row`,
    deleteBasicPolicyRow: `${MANAGE_COMPANY_ENDPOINT}/delete-basic-policy-row`,
    addInsuranceExtraPriceRow: `${MANAGE_COMPANY_ENDPOINT}/add-insurance-extra-price-row`,
    getAllExtrasTypes: `${MANAGE_COMPANY_ENDPOINT}/get-all-extras-types`,
    updateInsuranceExtraRow: `${MANAGE_COMPANY_ENDPOINT}/update-insurance-extra-row`,
  },
};

// HEADERS()
const headers = {
  "Content-Type": "application/json",
  // "x-access-token": getUserToken(),
};

export const GET_HEADERS = () => {
  return headers;
};

export const POST_HEADERS = () => {
  return {
    headers: headers,
  };
};
