import { useEffect, useState } from "react";
import { InsuranceSingleOption } from "./InsuranceSingleOption";
import { getExtrasListData } from "../../controller/dataController";
import { Box, Button, Grid, Paper, Tab, Tabs, Typography } from "@mui/material";
import { AppConsents, AppDict } from "../../includes/AppDictionary";
import PropTypes from "prop-types";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import { NextButtonForCompareStep } from "./NextButtonForCompareStep";
import { consentsColor } from "../../AppTheme";

export const InsuranceOptionsStep = (props) => {
  const [insuranceOptionsPricesList, setInsuranceOptionsPricesList] = useState(
    props["insuranceOptionsPricesList"] || {}
  );
  const [defaultExtrasList, setDefaultExtrasList] = useState([]);
  const [passangersList, setPassangerList] = useState(
    props["passangerList"] || []
  );
  const [tabValue, setTabValue] = useState(0);

  const handleChange = (event, newValue) => {
    setTabValue(newValue);
  };

  const onClickNextHandler = () => {
    props.goToNextStep(passangersList);
  };

  const changeInOptionValue = () => {
    // passangersList[1]["optionsList"] = []/;
    setPassangerList([...passangersList]);
    props.optionsDataChanged(passangersList);
  };

  useEffect(() => {
    if (props["passangerList"]) {
      setPassangerList(props["passangerList"]);
    }
    if (props["insuranceOptionsPricesList"]) {
      setInsuranceOptionsPricesList(props["insuranceOptionsPricesList"]);
    }
  }, [props]);

  const updateDefaultExrasList = async () => {
    let tempDefaulsOptionsList = await getExtrasListData();
    let tempPassnager = passangersList;
    // check if options list had changed.
    for (let i = 0; i < tempPassnager.length; i++) {
      let optionsList = tempPassnager[i]["optionsList"] || [];
      // if options quantity updated
      if (tempDefaulsOptionsList.length != optionsList.length) {
        tempPassnager[i]["optionsList"] = tempDefaulsOptionsList.map((obj) => ({
          ...obj,
        })); // deep copy must
      }
    }
    setPassangerList([...tempPassnager]);
    setDefaultExtrasList(tempDefaulsOptionsList);
  };

  useEffect(() => {
    updateDefaultExrasList();
  }, []);
  return defaultExtrasList && defaultExtrasList.length > 0 ? (
    <Box justifyContent={"center"}>
      <Paper
        elevation={5}
        sx={{
          marginX: "auto",
          marginY: "0",
          maxWidth: { xs: "90%", sm: "75%" },
        }}
      >
        <Tabs
          variant="scrollable"
          scrollButtons="auto"
          aria-label="scrollable auto tabs example"
          value={tabValue}
          onChange={handleChange}
        >
          {passangersList.map((passanger1, index) => {
            return (
              <Tab
                label={
                  <Box>{`${passanger1["firstName"]} (${passanger1["age"]})`}</Box>
                }
                key={index}
                icon={
                  <AccountCircleIcon
                    color={tabValue == index ? "primary" : "disabled"}
                  />
                }
              ></Tab>
            );
          })}
        </Tabs>
      </Paper>
      {passangersList.map((passanger, passanger_index) => {
        // console.log(passanger["optionsList"]);

        passanger["optionsList"] = passanger["optionsList"] || [
          ...defaultExtrasList,
        ];
        return (
          <TabPanel
            sx={{ margin: 0, width: "100%", padding: 0 }}
            value={tabValue}
            index={passanger_index}
            padding="0px"
            key={passanger_index}
          >
            {tabValue}
            <Grid
              container
              sx={{
                padding: 0,
                margin: "auto",
                width: { xs: "100%", md: "100%", lg: "80%", xl: "70%" },
              }}
            >
              {passanger["optionsList"] &&
                passanger["optionsList"].map((option, option_index) => {
                  return (
                    <Grid
                      key={option_index}
                      item
                      xs={6}
                      sm={6}
                      sx={{ padding: 0, my: "10px", display: "grid" }}
                    >
                      <InsuranceSingleOption
                        insuranceOptionsPricesList={insuranceOptionsPricesList}
                        passangerDetails={passanger}
                        updateOptionStatus={() => {
                          let newStatus =
                            !passanger["optionsList"][option_index]["status"];
                          passanger["optionsList"][option_index]["status"] =
                            newStatus;
                          changeInOptionValue();
                        }}
                        status={
                          passanger["optionsList"][option_index]["status"]
                        }
                        optionId={option["id"]}
                        sx={{ padding: "15px" }}
                        iconType={option["icon_type"]}
                        label={option["extra_display_name_he"]}
                        label2={option["extra_info_1"]}
                        key={`${passanger_index}-${option_index}`}
                      />
                    </Grid>
                  );
                })}
            </Grid>
          </TabPanel>
        );
      })}

      <Box sx={{ margin: "25px", fontSize: "13px" }}>
        <Typography variant="body3" sx={{ color: consentsColor }}>
          {AppConsents.extras_consents}
        </Typography>
      </Box>
      <Box textAlign={"center"}>
        <NextButtonForCompareStep
          onClickNextHandler={onClickNextHandler}
          text={AppDict.continue}
        ></NextButtonForCompareStep>
      </Box>
    </Box>
  ) : (
    ""
  );
};

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`vertical-tabpanel-${index}`}
      aria-labelledby={`vertical-tab-${index}`}
      {...other}
    >
      {value === index && <Box sx={{ p: 1 }}>{children}</Box>}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};
