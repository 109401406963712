import { HeaderScripts } from "./HeaderScripts";
import { AppConsts } from "../../includes/AppConsts";
import {
  AppBar,
  Box,
  Button,
  Container,
  ImageListItem,
  Toolbar,
  Typography,
} from "@mui/material";
import { Link } from "react-router-dom";

import IconButton from "@mui/material/IconButton";
import Menu from "@mui/material/Menu";
import MenuIcon from "@mui/icons-material/Menu";
import Avatar from "@mui/material/Avatar";
import Tooltip from "@mui/material/Tooltip";
import MenuItem from "@mui/material/MenuItem";
import AdbIcon from "@mui/icons-material/Adb";
import { useState } from "react";

// export const AppHeader = () => {
//   return (
//     <Box component={"div"} sx={{ width: "100%", bgcolor: "paper.main" }}>
//       {/* <h1>{AppConsts.APP_BRAND_NAME}</h1> */}
//       <Box
//         component="img"
//         sx={{ maxWidth: "120px", width: "100%", display: "inline-block" }}
//         src="https://micaza.co.il/wp-content/uploads/2022/11/%D7%9C%D7%95%D7%92%D7%95_%D7%9E%D7%99%D7%A7%D7%96%D7%94-removebg-preview.png"
//       />
//       <img />
//     </Box>
//   );
// };

export const AppHeader = (props) => {
  const [anchorElNav, setAnchorElNav] = useState(null);
  const [anchorElUser, setAnchorElUser] = useState(null);

  const handleOpenNavMenu = (event) => {
    setAnchorElNav(event.currentTarget);
  };
  const handleOpenUserMenu = (event) => {
    setAnchorElUser(event.currentTarget);
  };

  const handleCloseNavMenu = () => {
    setAnchorElNav(null);
  };

  const handleCloseUserMenu = () => {
    setAnchorElUser(null);
  };

  return (
    <AppBar sx={{ ...props.sx, bgcolor: "paper.main" }} position="sticky">
      <Container maxWidth="xl">
        <Toolbar disableGutters sx={{ justifyContent: "center" }}>
          <Link height="100%" to="https://www.saveli.co.il/">
            <ImageListItem key={"logo"}>
              <Box
                component="img"
                sx={{
                  height: { xs: "64px", sm: "86px" },
                }}
                alt="Your logo."
                src={"/saveli-logo-v-1.png"}
              />
            </ImageListItem>
          </Link>
          {/* <Box
            sx={{
              paddingLeft: "35px",
              flexGrow: 1,
              display: { xs: "none", md: "flex" },
            }}
          >
            {pages.map((page) => (
          <Button
            variant="contained"
            component="button"
            href={page["link"]}
          >
            {page["word"]}
          </Button>
        ))}
          </Box> */}

          <Box sx={{ flexGrow: 0 }}>
            <Menu
              sx={{ mt: "45px" }}
              id="menu-appbar"
              anchorEl={anchorElUser}
              anchorOrigin={{
                vertical: "top",
                horizontal: "right",
              }}
              keepMounted
              transformOrigin={{
                vertical: "top",
                horizontal: "right",
              }}
              open={Boolean(anchorElUser)}
              onClose={handleCloseUserMenu}
            >
              {/* {settings.map((setting) => (
              <MenuItem key={setting} onClick={handleCloseUserMenu}>
                <Typography textAlign="center">{setting}</Typography>
              </MenuItem>
            ))} */}
            </Menu>
          </Box>
        </Toolbar>
      </Container>
    </AppBar>
  );
};
