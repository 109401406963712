import {
  Autocomplete,
  Badge,
  Box,
  Button,
  ButtonBase,
  Card,
  CardContent,
  Chip,
  FormControl,
  Grid,
  IconButton,
  InputLabel,
  MenuItem,
  Paper,
  Select,
  TextField,
  Typography,
} from "@mui/material";
import { useEffect, useState } from "react";
import { AppDict } from "../../includes/AppDictionary";
import BasicCard from "../basic/BasicCard";

import "/node_modules/flag-icons/css/flag-icons.min.css";

export const DestinationSelect = (props) => {
  const [destinationList, setDestinationList] = useState(
    props["destinationList"] ? props["destinationList"] : []
  );
  const [selectedDstination, setSelectedDestination] = useState(
    props["selectedDestination"] ? props["selectedDestination"] : null
  );

  const [attractiveDestinations, setAttractiveDestinations] = useState([]);
  const filterFunction = (options, searchTerm) => {
    let filtered_options = options.filter((obj) => {
      return obj["country_display_name_he"].includes(searchTerm["inputValue"]);
    });
    return filtered_options;
  };

  const filterAttractiveDestinations = (destinations) => {
    if (destinations) {
      let tempAttractiveDestinations = [];
      for (let i = 0; i < destinations.length; i++) {
        if (destinations[i]["attractive"]) {
          tempAttractiveDestinations.push(destinations[i]);
        }
      }

      setAttractiveDestinations(tempAttractiveDestinations);
    }
  };

  useEffect(() => {
    setDestinationList(props["destinationList"]);
    setSelectedDestination(props["selectedDestination"]);
    filterAttractiveDestinations(props["destinationList"]);
  }, [props]);

  return (
    <Box>
      <FormControl fullWidth>
        {destinationList.length > 0 ? (
          <Autocomplete
            disablePortal
            getOptionLabel={(option) => option.country_display_name_he}
            isOptionEqualToValue={(a, b) => a["id"] == b["id"]}
            value={selectedDstination}
            onChange={(event, newValue) => {
              props.onChange(newValue);
            }}
            filterOptions={filterFunction}
            options={destinationList}
            renderOption={(props, item) => (
              <li {...props} key={item["country_display_name_he"]}>
                {" "}
                {item["country_display_name_he"]}
              </li>
            )}
            renderInput={(params) => (
              <TextField {...params} label={AppDict.select_destination} />
            )}
          />
        ) : (
          ""
        )}
      </FormControl>
      {!selectedDstination &&
      attractiveDestinations &&
      attractiveDestinations.length > 0 ? (
        <Box sx={{ width: "100%", marginTop: "15px" }}>
          <Typography>{AppDict.popular_destinations}</Typography>
          <Grid
            container
            sx={{ padding: "10px" }}
            justifyContent={"center"}
            spacing={4}
          >
            {attractiveDestinations.map((dest, index) => {
              return (
                <Grid
                  sx={{ cursor: "pointer" }}
                  onClick={() => {
                    props.onChange(dest);
                  }}
                  key={index}
                  item
                  xs={3}
                  sm={3}
                >
                  <Card
                    variant="elevation"
                    elevation={3}
                    sx={{
                      height: "100%",
                      padding: "5px",
                      borderRadius: "20px",
                      textAlign: "center",
                    }}
                  >
                    {dest["country_display_name_he"]}
                    <br />
                    <Box
                      sx={{
                        borderRadius: "100%",
                        fontSize: "40px",
                      }}
                      textAlign={"center"}
                    >
                      <Box
                        sx={{
                          borderRadius: "100%",
                        }}
                        className={`fi fi-${String(
                          dest["country_code"]
                        ).toLowerCase()} fis`}
                      ></Box>
                    </Box>
                  </Card>
                </Grid>
              );
            })}
          </Grid>
        </Box>
      ) : (
        ""
      )}
    </Box>
  );
};
