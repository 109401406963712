import { CompareStepClass } from "./CompareStepClass";

export class InsuranceOptionsClass extends CompareStepClass {
  constructor(stepData) {
    super(stepData);
  }

  isStepValid() {
    return true; // no required fields... so the step is always valid
  }
  updateStepData(newData) {
    this.data = newData;
  }
}