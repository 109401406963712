import { DemoContainer } from "@mui/x-date-pickers/internals/demo";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import dayjs from "dayjs";
import { useEffect, useState } from "react";
import { Box } from "@mui/system";
import { heIL } from "@mui/x-date-pickers/locales";

export function BasicDatePicker(props) {
  const [dateValue, setDateValue] = useState(props.date ? props.date : dayjs());

  useEffect(() => {
    setDateValue(props.date);
  }, [props, props["reloadFlag"]]);
  return (
    <Box sx={{ display: "flex" }}>
      <LocalizationProvider
        dateAdapter={AdapterDayjs}
        localeText={
          heIL.components.MuiLocalizationProvider.defaultProps.localeText
        }
      >
        <DemoContainer sx={{ margin: 0 }} components={["DatePicker"]}>
          <DatePicker
            disablePast
            minDate={props['minDate']}
            onChange={(newValue) => props.onChange(newValue)}
            defaultValue={dateValue}
            value={dateValue}
            label={props["label"]}
          />
        </DemoContainer>
      </LocalizationProvider>
      {props.children}
    </Box>
  );
}
