import {
  Grid,
  TextField,
  Paper,
  Typography,
  FormControl,
  Box,
} from "@mui/material";
import { AppDict } from "../../includes/AppDictionary";
import { useEffect, useState } from "react";
import dayjs from "dayjs";
import { BasicDatePicker } from "../basic/BasicDatePicker";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import { secondaryColor } from "../../AppTheme";
import { heIL } from "@mui/x-date-pickers/locales";
import { DemoContainer } from "@mui/x-date-pickers/internals/demo";
import { DateField } from "@mui/x-date-pickers";
import { calculateAgeByBirthdate } from "../../utils";

export const PassangerDetailCard = (props) => {
  const [passangerDetail, setPassangerDetail] = useState(props["passangerObj"]);
  const [passangerNumber, setPassangerNumber] = useState(
    props["passangerNumber"]
  );
  const [showErrorsFlag, setShowErrorsFlag] = useState(false);

  const [birthdate, setBirthdate] = useState("");
  const [birthdateErrorText, setBirthdateErrorText] = useState("");
  const [age, setAge] = useState(
    props["passangerObj"] && props["passangerObj"]["age"]
      ? props["passangerObj"]["age"]
      : 0
  );

  const onChangeBirthdate = (newDate) => {
    if (newDate && newDate.isValid() && newDate.isBefore(dayjs())) {
      let age = calculateAgeByBirthdate(newDate);
      setAge(age);
      setBirthdate(newDate);
      updatePassangerDetail({
        birthdate: newDate,
        age: age,
      });
      setBirthdateErrorText("");
    } else {
      setBirthdateErrorText(AppDict.wrong_date);
    }
  };

  const validateFirstName = (value) => {
    // TODO : Add your validation logic for first name
    if (value === "") return false;
    return value.trim() != "";
  };

  const validateLastName = (value) => {
    // TODO : Add your validation logic for last name
    if (value === "") return false;
    return value.trim() != "";
  };

  const validateId = (value) => {
    // TODO : Add your validation logic for ID
    return value.trim() != "";
  };

  const updatePassangerDetail = (data) => {
    console.log(data);
    let newPassangerDetail = { ...passangerDetail };
    for (let i = 0; i < Object.keys(data).length; i++) {
      let key = Object.keys(data)[i];
      newPassangerDetail[key] = data[key];
    }

    // TODO: if for every specific empty input, show specific error
    const isFirstNameValid = newPassangerDetail.firstName != "";
    const isLastNameValid = newPassangerDetail.lastName != "";
    const isIdValid = newPassangerDetail.lastName != "";

    newPassangerDetail.isValid =
      isFirstNameValid && isLastNameValid && isIdValid;

    setPassangerDetail(newPassangerDetail);
    console.log(newPassangerDetail);
    props.updateSpecificPassangerDetail(newPassangerDetail);
  };

  useEffect(() => {
    setPassangerDetail(props["passangerObj"]);
    setPassangerNumber(props["passangerNumber"]);
  }, [props]);

  return (
    <Paper
      sx={{
        margin: "15px",
        padding: "15px",
        borderRadius: "30px",
        background: "#fff",
      }}
    >
      <Box display={"flex"} sx={{ color: secondaryColor }}>
        <AccountCircleIcon
          sx={{ marginRight: "10px", marginBottom: "15px", fontWeight: "bold" }}
        />
        <Typography
          sx={{
            marginBottom: "15px",
            fontWeight: "bold",
            fontSize: "medium",
            color: secondaryColor,
          }}
        >
          {`${passangerDetail["firstName"] || ""} ${
            passangerDetail["lastName"] || ""
          }`}
        </Typography>
      </Box>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <LocalizationProvider
            dateAdapter={AdapterDayjs}
            localeText={
              heIL.components.MuiLocalizationProvider.defaultProps.localeText
            }
          >
            <DateField
              minDate={dayjs("01-01-2024")}
              onChange={(newval) => onChangeBirthdate(newval)}
              defaultValue={dayjs(passangerDetail.birthdate || "")}
              value={dayjs(passangerDetail.birthdate || "")}
              label={props["label"]}
              format="DD-MM-YYYY"
              autoComplete="on"
              helperText={birthdateErrorText}
            />
          </LocalizationProvider>
        </Grid>
        <Grid item xs={12}>
          <TextField
            type="text"
            inputProps={{ maxLength: 15 }}
            label={AppDict.first_name}
            variant="outlined"
            onChange={(newFirstNameValue) =>
              updatePassangerDetail({
                firstName: newFirstNameValue.target.value,
              })
            }
            error={
              passangerDetail["firstName"] == "" && showErrorsFlag
                ? true
                : true
            }
            value={passangerDetail.firstName || ""}
          />
        </Grid>
        <Grid item xs={12}>
          <FormControl error variant="standard">
            <TextField
              error={
                passangerDetail["lastName"] == "" && showErrorsFlag
                  ? true
                  : true
              }
              inputProps={{ maxLength: 15 }}
              type="text"
              label={AppDict.second_name}
              variant="outlined"
              onChange={(newLastNameValue) =>
                updatePassangerDetail({
                  lastName: newLastNameValue.target.value,
                })
              }
              color="warning"
              value={passangerDetail.lastName || ""}
            />
          </FormControl>
        </Grid>
        {/* <Grid item xs={12}>
          <TextField
            id="outlined-basic"
            label={AppDict.id}
            variant="outlined"
            onChange={(newIdValue) =>
              updatePassangerDetail({
                id: newIdValue.target.value,
              })
            }
            value={passangerDetail.id || ''}
          />
        </Grid> */}
      </Grid>
    </Paper>
  );
};
