import { useEffect, useState } from "react";
import BasicCard from "../basic/BasicCard";
import {
  Box,
  CardMedia,
  Typography,
  Grid,
  Chip,
  Paper,
  List,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Stack,
} from "@mui/material";
import { calculateDurationInDays, drawPrice } from "../../utils";
import { AppDict } from "../../includes/AppDictionary";
import { Swiper, SwiperSlide } from "swiper/react";
import StarIcon from "@mui/icons-material/Star";
import {
  Navigation,
  Pagination,
  Scrollbar,
  A11y,
  Controller,
} from "swiper/modules";

import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";
import "swiper/css/scrollbar";
import "../../assets/css/swiper-button-next.css";
import { getCompanyResultsMetaData } from "../../controller/dataController";
import { Link } from "react-router-dom";
import VerifiedUserIcon from "@mui/icons-material/VerifiedUser";
import ErrorIcon from "@mui/icons-material/Error";

export const ResultsPanelByCompany = (props) => {
  const [controlledSwiper, setControlledSwiper] = useState(null);

  const [insuranceOptionsPricesList, setInsuranceOptionsPricesList] = useState(
    props["insuranceOptionsPricesList"] || {}
  );
  const [comparisionData, setComparisionData] = useState(
    props["comparisionData"] || null
  );
  const [companiesList, setCompaniesList] = useState(
    props["companiesList"] || []
  );

  const [companiesToPrice, setCompaniesToPrice] = useState({});

  const [basicPolicyPrices, setBasicPolicyPrices] = useState(
    props["basicPolicyPrices"] || null
  );

  const [companiesResultsMetaData, setCompanyResultsMetaData] = useState(
    props["companiesResultsMetaData"] || {}
  );

  // ************************ Display Settings: START ******************************

  const [desktopDisplayOptions, setDesktopDisplayOptions] = useState(
    props["displayType"] == "vertical"
      ? {
          xs: "block",
          sm: "block",
          md: "block",
          lg: "block",
        }
      : {
          xs: "none",
          sm: "none",
          md: "block",
          lg: "block",
        }
  );
  const [mobileDisplayOptions, setMobileDisplayOptions] = useState(
    props["displayType"] == "vertical"
      ? {
          xs: "none",
          sm: "none",
          md: "none",
          lg: "none",
        }
      : {
          xs: "block",
          sm: "block",
          md: "none",
          lg: "v",
        }
  );

  // ************************ Display Settings: END ******************************

  /**
   * Calculating the price for the extra,
   * Splitted by age, days duration and max price for travel.
   * @return [type]
   */

  const getAdditionalPriceByCompanyAndExtra = (
    companyId,
    extraId,
    passAge,
    durationInDays
  ) => {
    let additionalPrice = 0;
    let maxPriceForTravel = 0;
    if (
      props["insuranceOptionsPricesList"] &&
      props["insuranceOptionsPricesList"][extraId] &&
      props["insuranceOptionsPricesList"][extraId]["price_list"]
    ) {
      let extraPriceList =
        props["insuranceOptionsPricesList"][extraId]["price_list"];

      for (let i = 0; i < extraPriceList.length; i++) {
        if (extraPriceList[i]["company_id"] == companyId) {
          if (
            passAge >= extraPriceList[i]["min_age"] &&
              (passAge <= extraPriceList[i]["max_age"] ||
            extraPriceList[i]["max_age"] == -1)
          ) {
            if (
              durationInDays > extraPriceList[i]["min_days_condition"] &&
              durationInDays >= extraPriceList[i]["max_days_condition"] &&
              extraPriceList[i]["max_days_condition"] != -1
            ) {
              additionalPrice +=
                extraPriceList[i]["price_type"] == "daily"
                  ? extraPriceList[i]["max_days_condition"] *
                    extraPriceList[i]["price"]
                  : extraPriceList[i]["price"];

              maxPriceForTravel =
                extraPriceList[i]["max_price_for_travel"] != -1
                  ? extraPriceList[i]["max_price_for_travel"]
                  : maxPriceForTravel;
            } else if (
              durationInDays > extraPriceList[i]["min_days_condition"] &&
              (durationInDays < extraPriceList[i]["max_days_condition"] ||
                extraPriceList[i]["max_days_condition"] == -1)
            ) {
              // if the minimum days are 0 so we calculating the duration days, but if it is different from 0
              // we are calculating duration + 1
              // example : if the condition is : 0-30 for 0.5$ and 31+ for 1$, and the duration is 50 days.
              // so 30 - 0 is 30days but 50-31 is 19 days and we need to calculate 20 days.
              let duration =
                extraPriceList[i]["min_days_condition"] == 0
                  ? durationInDays
                  : durationInDays + 1;

              additionalPrice +=
                extraPriceList[i]["price_type"] == "daily"
                  ? (duration - extraPriceList[i]["min_days_condition"]) *
                    extraPriceList[i]["price"]
                  : extraPriceList[i]["price"];

              maxPriceForTravel =
                extraPriceList[i]["max_price_for_travel"] != -1
                  ? extraPriceList[i]["max_price_for_travel"]
                  : maxPriceForTravel;
            }
          }
        }
      }
    }
    let res =
      maxPriceForTravel != 0
        ? Math.min(maxPriceForTravel, additionalPrice)
        : additionalPrice;

    return res;
  };

  const initialCompaniesPrices = () => {
    const durationInDays = calculateDurationInDays(
      comparisionData["destinationAndDate"]["fromDate"],
      comparisionData["destinationAndDate"]["toDate"]
    );

    let companiesToPriceObjTemp = {};
    let passangerList = props["comparisionData"]["passangerList"];

    // initial
    for (let i = 0; i < companiesList.length; i++) {
      companiesToPriceObjTemp[companiesList[i]["id"]] = {
        price: 0,
        price_type: "daily",
        company_name: companiesList[i]["company_name"],
        company_id: companiesList[i]["id"],
        exceptions: [],
      };
    }

    for (let i = 0; i < basicPolicyPrices.length; i++) {
      // by users
      let basicPriceObjByCompany = basicPolicyPrices[i]["basicPolicyPrice"];
      for (let j = 0; j < basicPriceObjByCompany.length; j++) {
        // by companies
        let additionalPrice = 0;
        let insurance_company_id =
          basicPriceObjByCompany[j]["insurance_company_id"];

        if (companiesToPriceObjTemp[insurance_company_id]) {
          if (
            durationInDays > basicPriceObjByCompany[j]["min_days_condition"] &&
            durationInDays >= basicPriceObjByCompany[j]["max_days_condition"] &&
            basicPriceObjByCompany[j]["max_days_condition"] != -1
          ) {
            // there are 2 types of price, daily and one time.
            additionalPrice =
              basicPriceObjByCompany[j]["max_days_condition"] *
              basicPriceObjByCompany[j]["daily_price"];
          } else if (
            durationInDays > basicPriceObjByCompany[j]["min_days_condition"] &&
            (durationInDays < basicPriceObjByCompany[j]["max_days_condition"] ||
              basicPriceObjByCompany[j]["max_days_condition"] == -1)
          ) {
            additionalPrice =
              (durationInDays -
                basicPriceObjByCompany[j]["min_days_condition"]) *
              basicPriceObjByCompany[j]["daily_price"];
          }
          // case 1 : 30 > min and 30 > max and max != -1 => max*price
          // case 2 : 30 > min and 30 < max || max == -1 => (30 - min) * price

          companiesToPriceObjTemp[insurance_company_id]["price"] +=
            additionalPrice;
        } else {
          companiesToPriceObjTemp[insurance_company_id] = {
            price: additionalPrice,
          };
        }
      }
    }

    // extras
    for (let i = 0; i < passangerList.length; i++) {
      let passangerOptionsList = passangerList[i]["optionsList"] || [];
      let passAge = passangerList[i]["age"];
      for (let i = 0; i < passangerOptionsList.length; i++) {
        if (passangerOptionsList[i]["status"] != true) {
          continue;
        }
        let extraId = passangerOptionsList[i]["id"];
        for (let j = 0; j < companiesList.length; j++) {
          let compId = companiesList[j]["id"];
          let res = getAdditionalPriceByCompanyAndExtra(
            compId,
            extraId,
            passAge,
            durationInDays
          );
          if (res == 0) {
            // extra not exist for this cusotmer for example - passanger 12 years old trying to take card renting.
            let exception = {
              text: `${AppDict.company_does_not_include_this_extra} ${props["insuranceOptionsPricesList"][extraId]["extra_display_name_he"]} ${AppDict.due_to_unmatching_with_age_or_duration}`,
            };
            companiesToPriceObjTemp[compId]["exceptions"] =
              companiesToPriceObjTemp[compId]["exceptions"]
                ? [...companiesToPriceObjTemp[compId]["exceptions"], exception]
                : [exception];
          } else {
            companiesToPriceObjTemp[compId]["price"] += res;
          }
        }
      }
    }

    let companiesToPriceArrTempSorted = Object.values(companiesToPriceObjTemp);

    companiesToPriceArrTempSorted = companiesToPriceArrTempSorted.sort(
      function (a, b) {
        // compare by price and exceptions.
        if (a["exceptions"].length == b["exceptions"].length) {
          return parseFloat(a["price"]) - parseFloat(b["price"]);
        } else {
          return (
            parseFloat(a["exceptions"].length) -
            parseFloat(b["exceptions"].length)
          );
        }
      }
    );

    setCompaniesToPrice(companiesToPriceArrTempSorted);
  };

  useEffect(() => {
    if (
      basicPolicyPrices &&
      basicPolicyPrices.length > 0 &&
      comparisionData &&
      Object.keys(insuranceOptionsPricesList).length > 0 &&
      companiesList.length > 0
    ) {
      initialCompaniesPrices();
    }
  }, [
    basicPolicyPrices,
    comparisionData,
    insuranceOptionsPricesList,
    companiesList,
  ]);

  useEffect(() => {
    // initial all variables
    if (props["basicPolicyPrices"]) {
      setBasicPolicyPrices(props["basicPolicyPrices"]);
    }
    if (props["comparisionData"]) {
      setComparisionData(props["comparisionData"]);
    }
    if (props["insuranceOptionsPricesList"]) {
      setInsuranceOptionsPricesList(props["insuranceOptionsPricesList"]);
    }
    if (props["companiesList"]) {
      setCompaniesList(props["companiesList"]);
    }
  }, [props]);

  const Advantages = (props) => {
    return (
      <List component="nav" aria-label="main mailbox folders">
        {props["advantages"].map((adv, index) => {
          return (
            <ListItemButton
              key={index}
              // selected={selectedIndex === 0}
              // onClick={(event) => handleListItemClick(event, 0)}
            >
              <ListItemIcon>
                <VerifiedUserIcon
                  sx={{ color: "primary.main", fontSize: "30px" }}
                />
              </ListItemIcon>
              <ListItemText
                secondaryTypographyProps={{ fontSize: "15px" }}
                secondary={adv}
              />
            </ListItemButton>
          );
        })}
      </List>
    );
  };

  const Exceptions = (props) => {
    return props["exceptions"]
      ? props["exceptions"].map((exeption, index) => {
          return (
            <Stack direction="row" alignItems="center" gap={1} key={index}>
              <ErrorIcon sx={{ fontSize: "20px" }} />
              <Typography
                sx={{
                  display: "flex",
                  alignItems: "center",
                  flexWrap: "wrap",
                  fontSize: "14px",
                  marginTop: "5px",
                }}
                key={index}
              >
                {exeption["text"]}
              </Typography>
            </Stack>
          );
        })
      : "";
  };

  const CompanyCard = (props) => {
    return (
      <BasicCard>
        <Box
          sx={{
            height: "100%",
            display: "flex",
            flexDirection: "column",
            justifyContent: "space-between",
          }}
        >
          {/* {props["index"] == 0 ? (
              <Box>
                <Chip
                  label={AppDict.bestOffer}
                  color="success"
                  variant="outlined"
                >
                </Chip>
              </Box>
            ) : (
              ''
            )} */}
          <Box>
            {/* {props["companyName"]} */}
            <CardMedia
              sx={{ width: "50px", height: "100%", margin: "auto" }}
              component="img"
              image={`/company_icons/${props["companyId"]}-logo.png`}
            ></CardMedia>
          </Box>
          <Box sx={{ marginTop: "0", textAlign: "center" }}>
            <Typography
              id={`${props["companyId"]}-final-price`}
              sx={{ fontWeight: "600", fontSize: "15px" }}
            >
              {drawPrice(props["price"])}
            </Typography>
            {props["link"] ? (
              <a href={props["link"]} target="_blank">
                לחץ כאן
              </a>
            ) : (
              ""
            )}
          </Box>
          {props["showAdvantages"] ? (
            <Advantages
              advantages={
                props["metaData"]
                  ? props["metaData"]["comp_advantages"] || []
                  : []
              }
            ></Advantages>
          ) : (
            ""
          )}
          <Exceptions exceptions={props["exceptions"]}></Exceptions>
        </Box>
      </BasicCard>
      // </Grid>
    );
  };

  return (
    <Box>
      {/* Mobile */}
      <Box sx={{ display: mobileDisplayOptions }}>
        <Paper elevation={6}>
          {/* <Typography
            sx={{ padding: "5px", marginBottom: 0, paddingBottom: 0 }}
            fontWeight={500}
          >
            {AppDict.prices}:
          </Typography> */}
          <Swiper
            style={{
              "--swiper-navigation-color": "#000",
              "--swiper-navigation-size": "25px",
            }}
            navigation={true}
            modules={[Controller, Navigation, Pagination, Scrollbar, A11y]}
            controller={{ control: controlledSwiper }}
            pagination={{ clickable: true }}
            spaceBetween={10}
            slidesPerView={3.5}
          >
            {companiesToPrice.length > 0 &&
              companiesToPrice.map((element, index) => {
                let currentCompany = element;
                // if(currentCompany['daily_price'] == 0){
                //   return '';
                // }
                return (
                  <SwiperSlide key={index}>
                    <CompanyCard
                      index={index}
                      // exceptions={currentCompany["exceptions"]}
                      price={currentCompany["price"]}
                      companyName={currentCompany["company_name"]}
                      companyId={currentCompany["company_id"]}
                      link={
                        companiesResultsMetaData[currentCompany["company_id"]]
                          ? companiesResultsMetaData[
                              currentCompany["company_id"]
                            ]["link"]
                          : ""
                      }
                      // metaData={
                      //   companiesResultsMetaData[
                      //     currentCompany["company_id"]
                      //   ] || {}
                      // }
                    />
                  </SwiperSlide>
                );
              })}
          </Swiper>
        </Paper>
      </Box>
      {/* Desktop */}
      <Box sx={{ display: desktopDisplayOptions }}>
        <Box>
          {Object.keys(companiesToPrice).length > 0 &&
            Object.keys(companiesToPrice).map((element, index) => {
              let currentCompany = companiesToPrice[element];
              // if(currentCompany['daily_price'] == 0){
              //   return '';
              // }
              return (
                <Box key={index} sx={{ padding: "20px" }}>
                  <Grid
                    item
                    sx={{ my: "10px", height: "100%" }}
                    key={props["index"]}
                  >
                    <CompanyCard
                      exceptions={currentCompany["exceptions"]}
                      showAdvantages={props["stepNumber"] == 3} // showing advantages in the final step
                      index={index}
                      price={currentCompany["price"]}
                      companyName={currentCompany["company_name"]}
                      companyId={currentCompany["company_id"]}
                      link={
                        companiesResultsMetaData[currentCompany["company_id"]]
                          ? companiesResultsMetaData[
                              currentCompany["company_id"]
                            ]["link"]
                          : ""
                      }
                      metaData={
                        companiesResultsMetaData[
                          currentCompany["company_id"]
                        ] || {}
                      }
                    />
                  </Grid>
                </Box>
              );
            })}
        </Box>
      </Box>
    </Box>
  );
};
