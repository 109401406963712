import { Alert, Box, Button, Collapse, Fade, Typography } from "@mui/material";
import { BasicDatePicker } from "../basic/BasicDatePicker";
import FlightTakeoffIcon from "@mui/icons-material/FlightTakeoff";
import FlightLandIcon from "@mui/icons-material/FlightLand";
import { DestinationSelect } from "./DestinationSelect";
import { useEffect, useState } from "react";
import { AppDict } from "../../includes/AppDictionary";
import dayjs from "dayjs";
import { NextButtonForCompareStep } from "./NextButtonForCompareStep";
import {
  calculateDurationInDays,
  getDefaultDestinationStepData,
} from "../../utils";
import LocationOnIcon from '@mui/icons-material/LocationOn';
import LocalAirportIcon from '@mui/icons-material/LocalAirport';
const dateWrapperStyle = {
  mx: "auto",
  my: "30px",
};

export const DestinationAndDatesStep = (props) => {
  const [stepData, setStepData] = useState(getDefaultDestinationStepData());
  const [countryList, setCountryList] = useState([]);
  const [flightInDays, setFlightInDays] = useState(0);
  const [showDateError, setShowDateError] = useState(false);
  const [stepDataValid, setStepDataValid] = useState(false);
  const [destinationChangedTrigger, setDestinationChangedTrigger] = useState(false);

  const [isDestinationValid,setIsDestinationValid] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");

  const onDestinationChanged = (newDest)=>{

  }

  // TODO:Initial from localstorage not working
  const validateStepDataFormat = (data) => {
    let newStepData = { ...data };
    try {
      newStepData["fromDate"] = newStepData["fromDate"]
        ? dayjs(newStepData["fromDate"])
        : dayjs();
      newStepData["toDate"] = newStepData["toDate"]
        ? dayjs(newStepData["toDate"])
        : dayjs();
      if (newStepData["selectedDestination"]) {
        newStepData["selectedDestination"] = newStepData["selectedDestination"];
      }
    } catch {}
    return newStepData;
  };

  const updateStepData = (data) => {
    let newStepData = { ...stepData };
    if (data["fromDate"]) {
      newStepData["fromDate"] = data["fromDvalte"];
    }
    if (data["toDate"]) {
      newStepData["toDate"] = data["toDate"];
    }
    if (data["selectedDestination"]) {
      newStepData["selectedDestination"] = data["selectedDestination"];
    }
    setStepData(newStepData);
    props.sendStepDataToMainComponent(newStepData);
  };

  const showDateAlert = (errorMessage) => {
    setErrorMessage(errorMessage);
    setShowDateError(true);
  };

  const hideDateAlert = () => {
    setShowDateError(false);
  };

  const onClickNextHandler = () => {
    // validaiton before moving to the next step
    let fromDate = stepData["fromDate"];
    let toDate = stepData["toDate"];
    let today = new dayjs();
    // check if date is in the past

    if (calculateDurationInDays(today, fromDate) < 0) {
      showDateAlert(AppDict.illigal_flight_dates);
      return false;
    }

    let diff = calculateDurationInDays(fromDate, toDate);
    if (diff == 0) {
      setFlightInDays(1);
    } else if (diff > 0) {
      setFlightInDays(diff + 2);
    } else {
      // Illigal Dates - Show Alert
      showDateAlert(AppDict.illigal_flight_dates);
      return false;
    }

    /* Success : Go To Next Step */
    props.sendStepDataToMainComponent(stepData);
    props.goToNextStep(stepData);
    return true;
  };

  // Check if the step data is valid
  const validateStepData = () => {
    let fromDate = stepData["fromDate"];
    let toDate = stepData["toDate"];
    let tempFlightInDays = calculateDurationInDays(fromDate, toDate);
    // if to date is beofre from date.
    if (tempFlightInDays <= 0) {
      // showDateAlert(AppDict.illigal_flight_dates);
      setStepData({ ...stepData, toDate: fromDate });
    } else {
      hideDateAlert();
    }
    let res = tempFlightInDays >= 0 && stepData["selectedDestination"];
    setStepDataValid(res);
    if(stepData["selectedDestination"]){
      setIsDestinationValid(true);
    }else{
      setIsDestinationValid(false);
    }
    return res;
  };

  useEffect(() => {
    validateStepData();
  },[stepData]);

  useEffect(() => {
    setStepData(validateStepDataFormat(props["stepData"]));

    setCountryList(props["countryList"]);
  }, [props]);

  useEffect(() => {
    let fromDate = stepData["fromDate"];
    let toDate = stepData["toDate"];
    let tempFlightInDays = calculateDurationInDays(fromDate, toDate);
    setFlightInDays(tempFlightInDays);
  }, [stepData]);

  return (
    <Box maxWidth={"800px"}>
      <DestinationSelect
      destinationChanged={()=>{setDestinationChangedTrigger(!destinationChangedTrigger)}}
        selectedDestination={stepData["selectedDestination"]}
        destinationList={countryList}
        onChange={(newValue) => {
          updateStepData({ selectedDestination: newValue });
        }}
      />
      <Collapse in={isDestinationValid}><Box>
        <Box
          sx={{
            margin: "auto",
            flexWrap: "wrap",
            display: "flex",
            margin: 0,
            padding: 0,
            justifyContent: "space-between",
          }}
        >
          <Box onClick={hideDateAlert} sx={dateWrapperStyle}>
            <BasicDatePicker
              onChange={(newFromDateValue) =>
                setStepData({ ...stepData, fromDate: newFromDateValue })
              }
              date={stepData.fromDate}
              label={AppDict.take_off_flight}
            >
              <FlightTakeoffIcon sx={{ margin: "auto" }} />
            </BasicDatePicker>
          </Box>
          <Box onClick={hideDateAlert} sx={dateWrapperStyle}>
            <BasicDatePicker
              minDate={stepData["fromDate"]}
              onChange={(newToDateValue) =>
                setStepData({ ...stepData, toDate: newToDateValue })
              }
              date={stepData["toDate"]}
              label={AppDict.landing_flight}
            >
              <FlightLandIcon sx={{ margin: "auto" }} />
            </BasicDatePicker>
          </Box>
        </Box>
        {showDateError ? <Alert severity="warning">{errorMessage}</Alert> : ""}
        {flightInDays > 0 ? (
          <Typography gutterBottom variant="body2">
            {AppDict.total} {flightInDays} {AppDict.days}.
          </Typography>
        ) : (
          ""
        )}
  </Box>
  </Collapse>
  {!isDestinationValid ? <img className="width-100" src="/pics/temp-dest.png" /> : ''}

      <Box sx={{ textAlign: "center", my: "10px" }}>
        <NextButtonForCompareStep
          onClickNextHandler={onClickNextHandler}
          text={AppDict.continue}
          disabled={!stepDataValid}
        ></NextButtonForCompareStep>
      </Box>
    </Box>
  );
};
