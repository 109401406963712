import dayjs from "dayjs";

export const getDefaultDestinationStepData = () => {
  return {
    fromDate: dayjs(),
    toDate: dayjs(),
    selectedDestination: null,
    countryList: [],
  };
};

export const calculateAgeByBirthdate = (birthdate) => {
  const today = dayjs();
  let age = today.diff(birthdate, "year");
  return age;
};

export const getDefaultPassangerList = () => {
  return [
    {
      passangerIndex: 0,
      birthdate: "",
      age: 1,
      firstName: "",
      lastName: "",
      id: "",
      insuranceOptions: [],
    },
  ];
};

export const drawPrice = (value) => {
  //   "after : " + parseFloat(Math.ceil(newPriceByPercentage)).toFixed(2)
  if (isNaN(value)) {
    return "";
  } else {
    return `${parseFloat(value).toFixed(2)}$`;
  }
};

export const calculateDifferenceInDays = (fromDate, toDate) => {
  let fromDateobj = dayjs(fromDate).startOf("day");
  let toDateObj = dayjs(toDate).startOf("day");

  return toDateObj.diff(fromDateobj, "day");
};

export const calculateDurationInDays = (fromDate, toDate) => {
  return calculateDifferenceInDays(fromDate, toDate) + 1;
};

export const getAppSignatureKey = (key) => {
  return `${"app_sig_val_"}${key}`;
};

export const getSignatureForString = (s) => {
  if (!s) {
    return "";
  }
  return s.split("").reduce(function (a, b) {
    a = (a << 5) - a + b.charCodeAt(0);
    return a & a;
  }, 0);
};

export const stepDataValidation = (step, stepData) => {
  if (step == 1) {
    // destination and date
  } else if (step == 2) {
  } else if (step == 3) {
  }
};
