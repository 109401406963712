import { APP_CONSTS } from "../APP/APP_CONSTS";
import {
  getAppSignatureKey,
  getDefaultDestinationStepData,
  getDefaultPassangerList,
  getSignatureForString,
} from "../utils";
import { DestinationAndDatesStepClass } from "./DestinationAndDatesStepClass";
import { InsuranceOptionsClass } from "./InsuranceOptionsClass";
import { PassangerStepClass } from "./PassangerStepClass";

const LOWEST_STEP = 0;
const HIEGHST_STEP = 3;

export class MainCompareClass {
  constructor(dataChangedCallback) {
    this.destinationAndDate = new DestinationAndDatesStepClass(
      getDefaultDestinationStepData()
    );
    this.passangerList = new PassangerStepClass(getDefaultPassangerList());
    this.insuranceOptions = new InsuranceOptionsClass({});
    this.updateCounter = 10;
    this.dataChangedCallback = dataChangedCallback;
  }

  isStepValid(stepIndex) {
    if (stepIndex == 0) {
      return this.destinationAndDate.isStepValid();
    } else if (stepIndex == 1) {
      return this.passangerList.isStepValid();
    } else if (stepIndex == 2) {
      return this.insuranceOptions.isStepValid();
    }
  }

  // this function call the changed data callback with different value
  // to update data using changing stat in the main component.
  dataUpdated() {
    this.dataChangedCallback(this.updateCounter + 1);
    this.updateCounter++;
  }

  updateStepData(stepIndex, newData) {
    if (stepIndex == 0) {
      this.destinationAndDate.updateStepData(newData);
    } else if (stepIndex == 1) {
      this.passangerList.updateStepData(newData);
    } else if (stepIndex == 2) {
      this.insuranceOptions.updateStepData(newData);
    }
    this.dataUpdated();
  }

  updatePassangerList(newData) {
    this.passangerList.updateStepData(newData);
    this.dataUpdated();
  }

  updateDestinationAndDate(newData) {
    this.destinationAndDate.updateStepData(newData);
    this.dataUpdated();
  }

  updateInsuranceOptions(newData) {
    this.insuranceOptions.updateStepData(newData);
    this.dataUpdated();
  }

  toJSON() {
    return {
      destinationAndDate: this.destinationAndDate.getData(),
      passangerList: this.passangerList.getData(),
      insuranceOptions: this.insuranceOptions.getData(),
      policyVersion: APP_CONSTS.POLICY_VERSION,
    };
  }

  toJsonObj() {
    return {
      destinationAndDate: this.destinationAndDate.getData(),
      passangerList: this.passangerList.getData(),
      insuranceOptions: this.insuranceOptions.getData(),
    };
  }

  // check if data valid and only than updating it
  updateDataFromJSON(jsonData) {
    if (
      jsonData["destinationAndDate"] &&
      this.destinationAndDate.isStepValid(jsonData["destinationAndDate"])
    ) {
      let data = jsonData["destinationAndDate"];
      this.destinationAndDate.updateStepData(data);
    }
    if (
      jsonData["passangerList"] &&
      this.passangerList.isStepValid(jsonData["passangerList"])
    ) {
      let data = jsonData["passangerList"];
      this.passangerList.updateStepData(data);
    }
    if (
      jsonData["insuranceOptions"] &&
      this.insuranceOptions.isStepValid(jsonData["insuranceOptions"])
    ) {
      let data = jsonData["insuranceOptions"];
      this.insuranceOptions.updateStepData(data);
    }
    this.dataUpdated();
  }

  saveToLocalStorage() {
    // save all the data to localstorage
    localStorage.setItem("policy", JSON.stringify(this));
    localStorage.setItem(
      getAppSignatureKey("policy"),
      getSignatureForString(JSON.stringify(this))
    );
  }

  removeAllComparationDataFromLocalStorage() {
    localStorage.removeItem("policy"); // remove data
    localStorage.removeItem(getAppSignatureKey("policy")); // remove signature
  }

  async getAllDataFromLocalStorage() {
    // data validation
    let policyJsonData = localStorage.getItem("policy");
    if (!policyJsonData) {
      return;
    } // data not exist

    let oldSignature = localStorage.getItem(getAppSignatureKey("policy"));
    let newSignature = getSignatureForString(policyJsonData);

    if (oldSignature != newSignature) {
      // different means data changed
      this.removeAllComparationDataFromLocalStorage();
      return;
    }
    let comparisionDataJson = JSON.parse(localStorage.getItem("policy"));

    if (comparisionDataJson["policyVersion"] != APP_CONSTS.POLICY_VERSION) {
      console.log("new version - removing old data.");
      this.removeAllComparationDataFromLocalStorage();
      return;
    }

    this.updateDataFromJSON(comparisionDataJson);
    this.dataUpdated();
  }

  validateStepNumberValue(stepNumber) {
    return (
      stepNumber != null &&
      Number.isInteger(parseInt(stepNumber)) &&
      stepNumber >= LOWEST_STEP &&
      stepNumber <= HIEGHST_STEP
    );
  }
}
