import logo from "./logo.svg";
import "./App.css";
import { AppHeader } from "./components/sections/AppHeader";
import { AppFooter } from "./components/sections/AppFooter";
import BasicCard from "./components/basic/BasicCard";
import MainCompareStepper from "./components/compare/MainCompareStepper";
import { defaultTheme } from "./AppTheme";
import { ThemeProvider } from "@mui/material/styles";
import { Box } from "@mui/material";
import { HeaderScripts } from "./components/sections/HeaderScripts";

import rtlPlugin from "stylis-plugin-rtl";
import stylisRTLPlugin from "stylis-plugin-rtl";

import { prefixer } from "stylis";
import { CacheProvider } from "@emotion/react";
import createCache from "@emotion/cache";
import GeneralBrawser from "./components/basic/GeneralBrawser";

// Create rtl cache
const cacheRtl = createCache({
  key: "muirtl",
  stylisPlugins: [prefixer, rtlPlugin],
});

function App() {
  window.dataLayer.push({
    event: "pageview",
    page: {
      url: "/",
      title: "main",
    },
  });
  return (
    <CacheProvider value={cacheRtl}>
      <ThemeProvider theme={defaultTheme}>
        {/* <header className="App-header">
          <AppHeader />
        </header> */}
        <Box id="app-wrapper">
          <GeneralBrawser>
            <AppHeader sx={{ marginBottom: "10px" }}></AppHeader>
          </GeneralBrawser>
        </Box>
        {/* <Box sx={{paddingTop:"30px"}} className="App-main-board">
        </Box> */}
        <AppFooter></AppFooter>
      </ThemeProvider>
    </CacheProvider>
  );
}

export default App;
