import { Button } from "@mui/material";

export const NextButtonForCompareStep = (props) => {
  return (
    <Button
      sx={{
        fontSize: "22px",
        fontWeight: "500",
        minWidth: "150px",
        paddingLeft: "10px",
        paddingRight: "10px",
      }}
      onClick={props.onClickNextHandler}
      variant="contained"
      disabled={props.disabled}
    >
      {props.text || ""}
    </Button>
  );
};
