import { useEffect, useState } from "react";
import { TaarifonTable } from "../compare/TaarifonTables";
import { getCompaniesList } from "../../controller/dataController";
import { Box, Typography } from "@mui/material";
import { AppDict } from "../../includes/AppDictionary";
import { Title } from "../basic/Title";

export const CompaniesListPage = () => {
  return (
    <Box>
      <Title text={AppDict.companies} />
      <TaarifonTable></TaarifonTable>
    </Box>
  );
};
