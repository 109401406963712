export const AppDict = {
  destination_and_dates: "יעד ותאריכים",
  destination: "יעד",
  passangers_details: "פרטי נוסעים",
  select_insurance: "בוחרים ביטוח",
  select_destination: "בחר יעד",
  take_off_flight: "טיסת הלוך",
  landing_flight: "טיסת חזור",
  continue: "המשך",
  date_of_birth: "תאריך לידה",
  main_email: "טלפון ראשי",
  first_name: "שם פרטי",
  second_name: "שם משפחה",
  id: "תעודת זהות",
  passanger: "מבוטח",
  number_of_passangers: "מספר מבוטחים",
  illigal_flight_dates: "אופס.... התאריך לא חוקי, אנא וודא שהתאריך נכון",
  tavel_option: "אפשרויות בחירה",
  age: "גיל",
  passangerDataError: "אנא וודא שכלל הפרטים מלאים",
  vacationTo: "נוסעים ל",
  toThe: "עד ל:",
  total: "בסך הכל",
  days: "ימים",
  bestOffer: "המשתלם ביותר",
  prices: "מחירים",
  step: "שלב",
  passaners: "נוסעים",
  extras: "תוספות",
  insurance: "ביטוח",
  start_in: "החל מ",
  for_a_day: "ליום",
  company_does_not_include_this_extra: "חברה זו לא כולל את התוספת",
  due_to_unmatching_with_age_or_duration:
    "עקב חוסר התאמת גיל או אורך חופשה, ניתן לבדוק באתר החברה.",
  policy: "תקנון",
  agree_to_terms: "אני מסכימ/ה לתנאי השימוש.",
  popular_destinations: "יעדים פופלריים",
  wrong_date: "תאריך שגוי",
  company: "חברה",
  taarifon: "מחירון",
  company_link: "קישור לביטוח באתר החברה",
  companies: "חברות",
  click_here: "לחץ כאן",
};

export const AppConsents = {
  extras_consents:
    "מחירי התוספות המוצגים באתר הינם לפני הצהרה רפואית, כלומר לפני תוספות שייתכן ויחולו בהתאם למצב הרפואי של המבוטח ונתונים לשינוי על פי מדיניות חברות הביטוח. המידע באתר נועד לצורך הסבר כללי בלבד והוא כפוף לתנאי הפוליסות של כל חברת הביטוח. מידע זה אינו מהווה בסיס לקביעת חובות או תנאי הפוליסה ואינו מהווה כלי לפרשנות. במקרה של סתירה בין המידע באתר לתנאי הפוליסה בפועל כפי שניתנת על ידי החברה, יגברו תנאי הפוליסה של החברה. המידע באתר אינו מהווה ייעוץ מקצועי ואינו מהווה תחליף לייעוץ המתחשב במצבו הרפואי ובצרכיו האישיים של כל מבוטח. השימוש באתר הינו בהתאם לתנאי השימוש ומדיניות הפרטיות.",
};
