import { Box, FormControlLabel, Switch, Typography } from "@mui/material";
import BasicCard from "../basic/BasicCard";
import { useEffect, useState } from "react";
import { DateRangeIcon } from "@mui/x-date-pickers";
import LuggageIcon from "@mui/icons-material/Luggage";
import GppBadIcon from "@mui/icons-material/GppBad";
import CarRepairIcon from "@mui/icons-material/CarRepair";
import PhoneIphoneIcon from "@mui/icons-material/PhoneIphone";
import LaptopMacIcon from "@mui/icons-material/LaptopMac";
import DownhillSkiingIcon from "@mui/icons-material/DownhillSkiing";
import HikingIcon from "@mui/icons-material/Hiking";
import PregnantWomanIcon from "@mui/icons-material/PregnantWoman";
import MedicalServicesIcon from "@mui/icons-material/MedicalServices";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import { drawPrice } from "../../utils";
import { AppDict } from "../../includes/AppDictionary";
const getIconByType = (iconType, sx) => {
  // eslint-disable-next-line default-case
  switch (iconType) {
    case "baggage":
      return <LuggageIcon sx={sx} />;
    case "cancel":
      return <GppBadIcon sx={sx} />;
    case "medical":
      return <MedicalServicesIcon sx={sx} />;
    case "pragnant":
      return <PregnantWomanIcon sx={sx} />;
    case "sport":
      return <HikingIcon sx={sx} />;
    case "winter":
      return <DownhillSkiingIcon sx={sx} />;
    case "laptop":
      return <LaptopMacIcon sx={sx} />;
    case "mobile":
      return <PhoneIphoneIcon sx={sx} />;
    case "car":
      return <CarRepairIcon sx={sx} />;
  }
};

export const InsuranceSingleOption = (props) => {
  const [insuranceOptionsPricesList, setInsuranceOptionsPricesList] = useState(
    props["insuranceOptionsPricesList"] || {}
  );
  const [label, setLabel] = useState(props["label"]);
  const [optionId, setOptionId] = useState(props["optionId"]);
  const [status, setStatus] = useState(props["status"] ? 1 : 0); // 0 - OFF , 1 - ON
  const [price, setPrice] = useState(0);
  const [priceType, setPriceType] = useState("daily");
  const [passangerDetails, setPassangerDetails] = useState(
    props["passangerDetails"] || {}
  );
  const [isAvailable, setIsAvailable] = useState(true);

  const updatePrices = () => {
    let passAge = passangerDetails["age"];
    let extraPriceObj = insuranceOptionsPricesList[optionId];
    if (extraPriceObj) {
      for (let i = 0; i < extraPriceObj["price_list"].length; i++) {
        if (
          extraPriceObj["price_list"][i]["min_age"] <= passAge &&
          (extraPriceObj["price_list"][i]["max_age"] >= passAge ||
            extraPriceObj["price_list"][i]["max_age"] == -1) 
        ) {
          setPriceType(extraPriceObj["price_list"][i]["price_type"]);
          setPrice(extraPriceObj["price_list"][i]["price"]);
          return;
        }
      }
      // if loop ended without a return, there is no price for this option,
      // so it probably not relevant for age or duration...
      setIsAvailable(false);
    }
  };

  useEffect(() => {
    if (props["insuranceOptionsPricesList"]) {
      setInsuranceOptionsPricesList(props["insuranceOptionsPricesList"]);
    }
  }, [props]);

  useEffect(() => {
    if (passangerDetails && insuranceOptionsPricesList && optionId) {
      updatePrices();
    }
  }, [passangerDetails, insuranceOptionsPricesList, optionId]);

  return (
    <Box sx={props["sx"]}>
      <BasicCard>
        <Box
          sx={{
            height: "100%",
            display: "flex",
            justifyContent: "space-between",
          }}
        >
          <Box
            sx={{
              width: "100%",
              display: "flex",
              justifyContent: "space-between",
              flexDirection: "column",
            }}
          >
            <Box
              sx={{
                width: "100%",
                justifyContent: "space-between",
                display: "flex",
                flexDirection: "row",
              }}
            >
              <Box>
                <Switch
                  disabled={!isAvailable}
                  onChange={() => {
                    setStatus(1 - status);
                    props.updateOptionStatus();
                  }}
                  checked={status == 1}
                />
              </Box>
              <Box sx={{ display: "block" }}>
                {getIconByType(props["iconType"], {
                  fontSize: "50px",
                  // width: "80px",
                  color: status == 1 ? "primary.main" : "#d5d4d3",
                })}
              </Box>
            </Box>
            <Typography
              color="secondary"
              variant="caption"
              display="block"
              gutterBottom
            >
              {label}
            </Typography>
            <Typography
              color="secondary"
              variant="caption"
              display="block"
              gutterBottom
            >
              {props["label2"]}
            </Typography>
            {isAvailable ? (
              <Typography variant="body4">
                {AppDict.start_in} - {drawPrice(price)} {AppDict.for_a_day}
              </Typography>
            ) : (
              ""
            )}
          </Box>
        </Box>
      </BasicCard>
    </Box>
  );
};
