import axios from "axios";
import { APP_ROUTES, GET_HEADERS, POST_HEADERS } from "../APP/APP_ROUTES";
import { APP_CONSTS } from "../APP/APP_CONSTS";

export const getCountryListData = () => {
  let url = `${APP_CONSTS.APP_SERVER_HOST}country-list`;
  return axios
    .get(url, { headers: GET_HEADERS() })
    .then((result) => {
      if (result && result["data"]) {
        return result.data;
      } else {
        return false;
      }
    })
    .catch((err) => {
      return false;
    });
};

export const getCompanyResultsMetaData = () => {
  let url = `${APP_CONSTS.APP_SERVER_HOST}companies-results-meta-data`;
  return axios
    .get(url, { headers: GET_HEADERS() })
    .then((result) => {
      if (result && result["data"]) {
        return result.data;
      } else {
        return false;
      }
    })
    .catch((err) => {
      return false;
    });
};


export const getCompaniesList = async ()=>{
  let url = `${APP_CONSTS.APP_SERVER_HOST}companies-list`;
  return axios
    .get(url, { headers: GET_HEADERS() })
    .then((result) => {
      if (result && result["data"]) {
        return result.data;
      } else {
        return false;
      }
    })
    .catch((err) => {
      return false;
    });
}

export const getExtrasListData = () => {
  let url = `${APP_CONSTS.APP_SERVER_HOST}extras-list`;
  return axios
    .get(url, { headers: GET_HEADERS() })
    .then((result) => {
      if (result && result["data"]) {
        return result.data;
      } else {
        return false;
      }
    })
    .catch((err) => {
      return false;
    });
};

export const getBasicPolicyPrices = async (comparisionObj)=>{
  let url = `${APP_CONSTS.APP_SERVER_HOST}basic-policy-prices`;
  return axios
    .post(url, comparisionObj,{ headers: POST_HEADERS() })
    .then((result) => {
      if (result && result["data"]) {
        return result.data;
      } else {
        return false;
      }
    })
    .catch((err) => {
      return false;
    });
  }



  export const getInsuranceOptionsPricesList = async (comparisionObj)=>{
    let url = `${APP_CONSTS.APP_SERVER_HOST}insurance-options-price-list`;
    return axios
      .post(url, comparisionObj,{ headers: POST_HEADERS() })
      .then((result) => {
        if (result && result["data"]) {
          return result.data;
        } else {
          return false;
        }
      })
      .catch((err) => {
        return false;
      });
    }

  

