import { useContext, useState } from "react";
import React from "react";
import { Route, BrowserRouter, Routes } from "react-router-dom";
// import { authContext } from "./APP/Utils";
import MainCompareStepper from "../compare/MainCompareStepper";
import { CompaniesListPage } from "../pages/CompaniesListPage";
import { PageLayout } from "../pages/PageLayout";

function GeneralBrawser(props) {
  return (
    <BrowserRouter>
      {props.children}
      <Routes>
        <Route path="/" element={<MainCompareStepper />}></Route>
        <Route path="pages" element={<PageLayout />}>
          <Route
            path="companies"
            element={<CompaniesListPage/>}
          ></Route>
        </Route>
      </Routes>
    </BrowserRouter>
  );
}

export default GeneralBrawser;
